// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrls: {
    sso: 'https://ssoqas.compassmanager.com',
    rbac: 'https://adamsqas.compassmanager.com',
    oauthLambda: 'https://0rjyas9i23.execute-api.us-east-1.amazonaws.com/dev/oauth2.0',  
    oData: 'https://api.cg-truedata-test.com/OData/v1/',
    api: 'https://alb.cg-truedata-test.com/',
    redirectUri: 'https://0rjyas9i23.execute-api.us-east-1.amazonaws.com/dev/oauth2.0'  
  },
  omsConfig: {
    ssoClientId: '17iQwXCK4lT3jLPRYO5erXvaSIT1YnO8',
    clientId: '17iQwXCK4lT3jLPRYO5erXvaSIT1YnO8',
    clientSecret: 'EZ9Bs1Ne9WyC8nyAIDBoNXBkTR2RZ4mb',
    authUrl: 'https://ssoqas.compassmanager.com/oauth2.0/accessToken',
    grantType: 'client_credentials'
  }
};
